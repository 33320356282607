<template>
  <Modal v-model="modalShow" width="1000" title="资源排队情况" :footer-hide="true">
    <Table :columns="queueColumns" :data="queueData" stripe></Table>
  </Modal>
</template>

<script>
import { getQueueList, deleteQueue } from '@/api/order/orderqueue'
import { GetCurrentSchedule } from '@/utils/dateFormat'

export default {
  data () {
    return {
      modalShow: false,
      orderId: null,

      queueColumns: [
        { title: '资源名称', key: 'signName' },
        {
          title: '排队档期',
          render: (h, data) => {
            return h('span', GetCurrentSchedule(data.row.startDate, data.row.endDate))
          }
        },
        { title: '创建时间', key: 'createTime' },
        // { title: '预计释放时间', key: 'expireTime' },
        {
          title: '状态',
          width: 120,
          align: 'center',
          render: (h, data) => {
            return h('span', data.row.statusName)
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            if (params.row.status === 1) {
              return h('a', {
                on: {
                  click: () => {
                    this.handleCancelQueue(params.row.id)
                  }
                }
              }, '取消排队')
            } else {
              return h('span', '-')
            }
          }
        }
      ],
      queueData: []
    }
  },
  methods: {
    showModal (orderId) {
      this.orderId = orderId
      this.modalShow = true
      this.loadOrderQueneList()
    },
    loadOrderQueneList () {
      getQueueList({ orderId: this.orderId }).then(res => {
        this.queueData = res
      })
    },
    handleCancelQueue (queueId) {
      this.$Modal.confirm({
        title: '确定取消？',
        content: '<p>确定要取消当前资源排队吗？</p>',
        loading: true,
        onOk: () => {
          const postData = {
            orderId: this.orderId,
            queueId: queueId
          }

          deleteQueue(postData).then(res => {
            if (res && !res.errcode) {
              this.loadOrderQueneList()
              this.$Notice.success({ desc: '取消排队成功。' })
            }
          })
          this.$Modal.remove()
        }
      })
    }
  }
}
</script>
