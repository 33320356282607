<template>
    <div>
      <Divider dashed ><span class="divider-text">方案特征</span></Divider>
        <p class="p-l-10">
          <span class="title">历史拟合度 </span> {{similarityBean.historySimilarity}} %
        </p>
        <p class="p-l-10">
          <span class="title">行业拟合度 </span> {{similarityBean.industrySimilarity}} %
        </p>
    </div>
</template>

<script>
import { similarity } from '@/api/dw/recommend'

export default {
  data () {
    return {
      similarityBean: {}
    }
  },
  created () {
    this.loadOrderFeature()
  },
  methods: {
    loadOrderFeature () {
      const postData = { orderId: this.orderId }
      similarity(postData).then(res => {
        this.similarityBean = res
      })
    }
  },
  computed: {
    orderId () {
      return this.$store.state.order.orderId
    },
    beginUpdatePurchase () {
      return this.$store.state.order.beginUpdatePurchase
    }
  },
  watch: {
    beginUpdatePurchase (val) {
      this.loadOrderFeature()
    },
    orderId () {
      this.loadOrderFeature()
    }
  }
}
</script>
