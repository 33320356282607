<template>
  <div>
    <Divider dashed ><span class="divider-text">客流画像</span></Divider>

    <Row :gutter="8" class="m-t-5 m-b-10">
      <i-col span="12">
        <div class="station-new-portray-card">
          <p class="text-14 text-gray m-b-2">日均总客运量</p>
          <Row>
            <i-col span="16" class="text-16"><b>{{formatNumber(stationFlowTotal)}}</b></i-col>
            <i-col span="8" class="text-right text-gray" style="padding-top: 4px;">人次</i-col>
          </Row>
        </div>
      </i-col>
      <i-col span="12">
        <div class="station-new-portray-card">
          <p class="text-14 text-gray m-b-2">车站数量</p>
          <Row>
            <i-col span="16" class="text-16"><b>{{formatNumber(stationCount)}}</b></i-col>
            <i-col span="8" class="text-right text-gray" style="padding-top: 4px;">个</i-col>
          </Row>
        </div>
      </i-col>
    </Row>

    <Row :gutter="8" class="m-t-5 m-b-10">
      <i-col span="12">
        <div class="station-new-portray-card">
          <p class="text-14 text-gray m-b-2">
            月均游客量
            <Tooltip placement="bottom-end" transfer max-width="300">
              <a><Icon type="md-help-circle" size="16" /></a>
              <div slot="content">
                <p><span class="m-r-10">月均游客</span>基于手机定位和信令数据，计算进入该区域并停留一定时长（提出过境人群，停留时长根据统计范围大小有区别）得到的单月实际到访人次。</p>
              </div>
            </Tooltip>
          </p>
          <Row>
            <i-col span="16" class="text-16"><b>{{keliu}}</b></i-col>
            <i-col span="8" class="text-right text-gray" style="padding-top: 4px;">万人/次</i-col>
          </Row>
        </div>
      </i-col>
      <i-col span="12">
        <div class="station-new-portray-card">
          <p class="text-14 text-gray m-b-2">
            常驻人口
            <Tooltip placement="bottom-end" transfer max-width="300">
              <a><Icon type="md-help-circle" size="16" /></a>
              <div slot="content">
                <p><span class="m-r-10">常驻人口</span>居住人口+工作人口-重复</p>
              </div>
            </Tooltip>
          </p>
          <Row>
            <i-col span="16" class="text-16"><b>{{changzhu}}</b></i-col>
            <i-col span="8" class="text-right text-gray" style="padding-top: 4px;">万人</i-col>
          </Row>
        </div>
      </i-col>
    </Row>

    <Row :gutter="8" class="m-t-5 m-b-10">
      <i-col span="12">
        <div class="station-new-portray-card">
          <p class="text-14 text-gray m-b-2">
            居住人口
            <Tooltip placement="bottom-end" transfer max-width="300">
              <a><Icon type="md-help-circle" size="16" /></a>
              <div slot="content">
                <p><span class="m-r-10">居住人口</span>时间：晚9点-早7点（周一至周五）&早7点-晚8点（周六至周日）小区内同一台设备一个月内出现10次以上或者出现次数最多的点位</p>
              </div>
            </Tooltip>
          </p>
          <Row>
            <i-col span="16" class="text-16"><b>{{juzhu}}</b></i-col>
            <i-col span="8" class="text-right text-gray" style="padding-top: 4px;">万人</i-col>
          </Row>
        </div>
      </i-col>
      <i-col span="12">
        <div class="station-new-portray-card">
          <p class="text-14 text-gray m-b-2">
            工作人口
            <Tooltip placement="bottom-end" transfer max-width="300">
              <a><Icon type="md-help-circle" size="16" /></a>
              <div slot="content">
                <p><span class="m-r-10">工作人口</span>时间：早8点-晚7点（周一至周五）写字楼或园区内同一台设备一个月内出现10次以上或者出现次数最多的点位</p>
              </div>
            </Tooltip>
          </p>
          <Row>
            <i-col span="16" class="text-16"><b>{{gongzuo}}</b></i-col>
            <i-col span="8" class="text-right text-gray" style="padding-top: 4px;">万人</i-col>
          </Row>
        </div>
      </i-col>
    </Row>

    <p v-show="stationCount>0" class="text-right" @click="handleShowMore"><a>显示更多</a></p>

    <Drawer title="订单标签数据汇总" width="420" :closable="false" v-model="isShow">
      <OrderPortrayDetail ref="orderPortrayDetail"/>
    </Drawer>
  </div>
</template>

<script>
import OrderPortrayDetail from './OrderPortrayDetail'
import { toNumber } from '@/utils/wnumb_own'

import { getOrderStationList } from '@/api/order/order'
import { getStationPopulation } from '@/api/dm/sun'
import { countHalfYearAvgDayFlowGbStation } from '@/api/dw/passengerflow'

export default {
  components: {
    OrderPortrayDetail
  },
  data () {
    return {
      isShow: false,

      stationIds: null,
      stationFlowTotal: 0,
      stationCount: 0,
      keliu: 0,
      changzhu: 0,
      juzhu: 0,
      gongzuo: 0
    }
  },
  mounted () {
    this.loadOrderPortray()
  },
  methods: {
    loadOrderPortray () {
      // 获取当前订单下的所有站点集合
      const query = { orderIds: JSON.stringify([this.orderId]) }
      getOrderStationList(query).then(res => {
        // 计算站点数量， 需要排除换乘站
        const stationNames = []
        res.forEach(element => {
          if (!stationNames.includes(element.stationName)) {
            stationNames.push(element.stationName)
          }
        })
        this.stationCount = stationNames.length

        // 获取站点人口数据
        this.stationIds = res.map(x => x.stationId).join(',')
        if (this.stationIds) {
          this.loadStationPopulation()
        } else {
          this.changzhu = 0
          this.juzhu = 0
          this.gongzuo = 0
          this.keliu = 0
        }

        // 获取站点客流数据
        countHalfYearAvgDayFlowGbStation({ stationIds: this.stationIds }).then(res => {
          let flowTotal = 0
          res.forEach(element => {
            flowTotal += element.flowIn
            flowTotal += element.flowTransfer
          })

          this.stationFlowTotal = flowTotal
        })
      })
    },
    loadStationPopulation () {
      const queryModel = {
        personType: 1, // 人口类型(1.常驻;2:客流;)
        stationIds: this.stationIds
      }
      // 获取站点的人口数据
      getStationPopulation(queryModel).then(res => {
        this.changzhu = (res.find(x => x.name === '常驻').number / 10000).toFixed(2)
        this.juzhu = (res.find(x => x.name === '居住').number / 10000).toFixed(2)
        this.gongzuo = (res.find(x => x.name === '工作').number / 10000).toFixed(2)
      })

      const queryModel2 = {
        personType: 2, // 人口类型(1.常驻;2:客流;)
        stationIds: this.stationIds
      }
      getStationPopulation(queryModel2).then(res => {
        this.keliu = res.length > 0 ? (res[0].number / 10000).toFixed(2) : 0
      })
    },
    handleShowMore () {
      const moreBean = {
        stationIds: this.stationIds,
        stationFlowTotal: this.stationFlowTotal,
        stationCount: this.stationCount,
        keliu: this.keliu,
        changzhu: this.changzhu,
        juzhu: this.juzhu,
        gongzuo: this.gongzuo
      }

      this.isShow = true
      this.$refs.orderPortrayDetail.showDetail(moreBean)
    },
    formatNumber (number) {
      return toNumber(number)
    }
  },
  computed: {
    orderId () {
      return this.$store.state.order.orderId
    },
    beginUpdatePurchase () {
      return this.$store.state.order.beginUpdatePurchase
    }
  },
  watch: {
    beginUpdatePurchase (val) {
      this.loadOrderPortray()
    },
    orderId () {
      this.loadOrderPortray()
    }
  }
}
</script>
